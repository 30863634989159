<template>
  <div class="white--text">
    <div style="background: #a51f0a" class="px-2">
      <div class="d-flex py-3 justify-space-between fullWidth">
        <p class="my-auto md-text">ទំនាក់ទំនងមកពួកយើងឥឡូវនេះ</p>
        <v-dialog v-model="dialog2" :width="md ? '90%' : '70%'">
          <template v-slot:activator="{ on, attrs }">
            <button v-bind="attrs" v-on="on" class="rounded-pill white px-4 py-1 my-auto sm-text mr-2 btn"
              style="float: right">
              ទំនាក់ទំនង
            </button>
          </template>
          <ContactForm style="width: 100%" />
        </v-dialog>
      </div>
    </div>
    <a href="https://t.me/stvegas89" class="text-decoration-none white--text" target="_blank">
      <div>
        <v-img alt="STVEGAS89 Online game"
          src="https://res.cloudinary.com/dbzsjc7dt/image/upload/v1648866061/Game%20Online%20imgs/Frame_23_1_bkbfz1.webp"
          lazy-src="https://res.cloudinary.com/dbzsjc7dt/image/upload/v1648866061/Game%20Online%20imgs/Frame_23_1_bkbfz1.webp"><template
            v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row> </template></v-img>
      </div>
      <div class="LinearRed py-3">
        <div class="d-flex justify-space-between section px-2">
          <div class="md-text font-weight-bold my-auto">STVEGAS89</div>
          <!-- <div><p class="py-auto my-auto px-1 sm-text">STVEGAS89 © 2021 រក្សាសិទ្ធិគ្រប់យ៉ាង</p></div> -->
          <div class="d-flex" style="float: right">
            <div v-for="(media, i) in medias" :key="i">
              <a :href="media.link" target="_blank">
                <v-img max-height="4vw" max-width="4vw" class="mx-1" alt="STVEGAS89 Online game" :src="media.icon"
                  :lazy-src="media.icon"><template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row> </template></v-img>
              </a>
            </div>
            <p class="py-auto my-auto sm-text">ទូរស័ព្ទ :+855 (0) 86 896 003</p>
            <!-- <p class="py-auto my-auto px-1 sm-text">STVEGAS89 © 2021 រក្សាសិទ្ធិគ្រប់យ៉ាង</p>   -->
          </div>
        </div>
      </div>
      <div class="secondary my-0">
        <p class="py-3 my-0 sm-text text-center">
          STVEGAS89 © 2022 - {{ new Date().getFullYear() }} រក្សាសិទ្ធិគ្រប់យ៉ាង
        </p>
      </div>
    </a>
  </div>
</template>

<script>
import ContactForm from "../Contact/ContactForm.vue";


export default {

  data: () => ({

    currentDate: new Date,

    md: window.innerWidth <= 600,
    dialog2: false,
    medias: [
      {
        icon: "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1648909855/Game Online imgs/Facebook_wvekjx.webp",
        link: "http://m.me/109649748119492/?messaging_source=source%3Apages%3Amessage_shortlink&source_id=1441792",
      },
      {
        icon: "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1648909855/Game Online imgs/Telegram_yj9ykg.webp",
        link: "https://t.me/stvegas89",
      },
      {
        icon: "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1649228905/Game%20Online%20imgs/image_7_g4dc4r.webp",
        link: "https://line.me/R/ti/p/@656qogkh",
      },
      {
        icon: "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1649217574/Game%20Online%20imgs/image_8_ahpt8w.webp",
        link: "tel:+85586896003",
      },
    ],
  }),
  components: { ContactForm },
  // year method
  currentYear: new Date().getFullYear(), // 2020

};
</script>

<style scoped>
.btn {
  color: #a51f0a;
}

.btn:hover {
  background: linear-gradient(#ff5c00, #9a3800);
  color: white;
}
</style>