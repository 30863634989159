<template>
  <div class="white--text my-md-10 my-5">
    <div class="width">
      <!-- <h1
        class="LinearDarkYellow text-center mx-auto py-3 title-text1 game-card"
        style="animation: blink 1s infinite"
      >
        ប្រភេទហ្គេម
      </h1> -->
      <v-img
        max-height="5vw"
        max-width="50%"
        class="mx-auto"
        alt="STVEGAS89 Online game"
        style="animation: blinkYellowBottom 1000ms infinite"
        src="https://res.cloudinary.com/dbzsjc7dt/image/upload/v1648806895/Game%20Online%20imgs/Frame_17_p4atmp.webp"
        lazy-src="https://res.cloudinary.com/dbzsjc7dt/image/upload/v1648806895/Game%20Online%20imgs/Frame_17_p4atmp.webp"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div>
    <div class="fullWidth my-10">
      <v-row class="mx-0">
        <v-col
          v-for="(game, n) in games"
          :key="n"
          class="d-flex child-flex pa-0 mx-auto"
          cols="2"
        >
          <div
            class="my-md-3 my-1 card rounded-lg"
            style="background-color: #1b1f28"
          >
            <v-img
              alt="STVEGAS89 Online game"
              :src="game.img"
              :lazy-src="game.img"
              max-width="100%"
              max-height="100%"
              class="text-center rounded-t-lg"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <div class="my-md-4 my-2">
              <h1 class="text--white text-center sm-text pb-3">
                {{ game.title }}
              </h1>
              <v-img
                class="mx-auto img1 playnow"
                alt="STVEGAS89 Online game"
                src="https://res.cloudinary.com/dbzsjc7dt/image/upload/v1649312062/Game%20Online%20imgs/play_now_-_df_pdmu2a.webp"
                lazy-src="https://res.cloudinary.com/dbzsjc7dt/image/upload/v1649312062/Game%20Online%20imgs/play_now_-_df_pdmu2a.webp"
                style="cursor: pointer"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-img
                class="mx-auto img2 playnow"
                alt="STVEGAS89 Online game"
                src="https://res.cloudinary.com/dbzsjc7dt/image/upload/v1649312060/Game%20Online%20imgs/play_now_-_hv_ywdea7.webp"
                lazy-src="https://res.cloudinary.com/dbzsjc7dt/image/upload/v1649312060/Game%20Online%20imgs/play_now_-_hv_ywdea7.webp"
                ><template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row> </template
              ></v-img>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="section">
      <v-img
        alt="STVEGAS89 Online game"
        src="https://res.cloudinary.com/dbzsjc7dt/image/upload/v1648910424/Game%20Online%20imgs/Frame_25_ktnd8t.webp"
        lazy-src="https://res.cloudinary.com/dbzsjc7dt/image/upload/v1648806895/Game%20Online%20imgs/Frame_17_p4atmp.webp"
        ><template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row> </template
      ></v-img>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    md: window.innerWidth <= 600,
    games: [
      {
        id: 1,
        img: "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1649214850/Game%20Online%20imgs/Rectangle_493_3_gsgzxg.webp",
        title: "ចាក់បាល់",
      },
      {
        id: 2,
        img: "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1649227717/Game%20Online%20imgs/Frame_19_3_tcln8n.webp",
        title: "ជល់មាន់ 4ប៉ុស",
      },
      {
        id: 3,
        img: "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1648807614/Game%20Online%20imgs/Rectangle_493_1_ou33gq.webp",
        title: "កាស៊ីណូផ្សាយផ្ទាល់",
      },
      {
        id: 4,
        img: "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1648807611/Game%20Online%20imgs/Rectangle_493_2_liwwvc.webp",
        title: "ស្លតហ្គេម",
      },
      {
        id: 5,
        img: "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1649214848/Game%20Online%20imgs/Frame_19_1_v24tzk.webp",
        title: "បាញ់ត្រី",
      },
    ],
  }),
};
</script>

<style scoped>
.card {
  background-color: black;
  /* border: 2px solid rgba(0, 0, 0, 1); */
  box-shadow: 0 5px 5px #000;
}
.card:hover .img1 {
  display: none;
}
.card:hover .img2 {
  display: block;
}
.card:hover {
  margin: -5px;
}

.img2 {
  display: none;
}
@media screen and (max-width: 600px) {
  .playnow {
    max-width: 13vw;
    max-height: 8vw;
  }
}
@media screen and (min-width: 600px) {
  .playnow {
    max-width: 8vw;
    max-height: 3vw;
  }
}
</style>