<template>
  <div>
    <!-- <vueper-slides :touchable="true" lazy lazy-load-on-drag :slide-ratio="1 / 3" autoplay>
      <vueper-slide v-for="(slide, i) in slides" :key="i" :image="slide.src" :title="slide.title" :content="slide.content"
        height="300" link="https://t.me/stvegas89" open-in-new />
    </vueper-slides> -->
    <!-- slide -->
    <vueper-slides :touchable="true" lazy lazy-load-on-drag :slide-ratio="1 / 3" autoplay>
      <vueper-slide v-for="(slide, i) in slides" :key="i" :image="slide.feature_image" :title="slide.title" :content="slide.content"
        height="300" link="https://t.me/stvegas89" open-in-new />
    </vueper-slides>
    <!-- slide -->

    <a href="https://t.me/stvegas89" target="_blank">
      <div class="secondary">
        <v-row class="section">
          <v-img class="mx-auto my-auto" max-width="5vw" max-height="5vw" alt="STVEGAS89 Online game"
            src="https://res.cloudinary.com/dbzsjc7dt/image/upload/v1648803962/Game%20Online%20imgs/image_10_gfjiom.svg"
            lazy-src="https://res.cloudinary.com/dbzsjc7dt/image/upload/v1648803962/Game%20Online%20imgs/image_10_gfjiom.svg"><template
              v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row> </template></v-img>
          <v-col cols="10" class="my-auto mx-auto pa-0">
            <div id="rssBlock">
              <p class="cnnContents mb-0">
                <span class="marqueeStyle md-text LinearYellow font-weight-bold">រីករាយកំសាន្តជាមួយវេបសាយ STVEGAS89
                  ដែលជាវេបសាយផ្តល់នូវហ្គេមអនឡាញជាច្រើនប្រភេទ STVEGAS89
                  ស្វាគមន៍លេងលុយធំទាំងអស់ដក-ដាក់ប្រាក់សេរី
                </span>
                <span class="marqueeStyle2 md-text LinearYellow font-weight-bold">
                  រីករាយកំសាន្តជាមួយវេបសាយ STVEGAS89
                  ដែលជាវេបសាយផ្តល់នូវហ្គេមអនឡាញជាច្រើនប្រភេទ STVEGAS89
                  ស្វាគមន៍លេងលុយធំទាំងអស់ដក-ដាក់ប្រាក់សេរី</span>
              </p>
            </div>
          </v-col>
        </v-row>
      </div>
    </a>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
// read api
import axios from 'axios';

export default {
  components: {
    VueperSlides,
    VueperSlide,
  },

  // data: () => ({
  // slides: [
  //   {
  //     src: "https://res.cloudinary.com/dhnqh992h/image/upload/v1700320533/18_yre7ez.png",
  //   },
  //   {
  //     src: "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1648800879/Game%20Online%20imgs/Frame_15_1_snyir4.webp",
  //   },
  //   {
  //     src: "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1649312212/Game%20Online%20imgs/Frame_17_qerubh.webp",
  //   },
  // ],
  // timer: null,
  // }),
  data() {
    return {
      // multi item
      slides: []
    }
  },
  mounted() {
    this.getSlides();
  },
  methods: {
    getSlides() {
      axios.get('https://backend.stvegas89.com/api/promotion/slides').then(res => {
        this.slides = res.data.data
        console.log(this.data);
        // console.log(res);
      });
    },
  },
};
</script>

<style scoped>
img {
  height: auto;
  background-size: 100%;
  width: 100%;
}
</style>