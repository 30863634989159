import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path:'/promotion',
    name: "Promotion",
    component:() => import('../views/Promotion')
  },
  {
    path: '/contact',
    name: "Contact",
    component: () => import('../views/Contact')
  },
  {
    path: '/condition',
    name: 'Condition',
    component: () => import('../views/Condition')
  },
  {
    path: '/livescore',
    name: "Livescore",
    component : () => import ('../views/LiveScore')
  },
  {
    path: '/register',
    name: "User",
    component : () => import ('../views/User')
  },
  // {
  //   path:'/sitemap',
  //   name: "Sitemap",
  //   component: () => import ('../components/sitemap.xml')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
})

export default router
