<template>
  <div>
    <section>
      <Slider />
    </section>
    <section>
      <a
        href="https://t.me/stvegas89"
        class="text-decoration-none white--text"
        target="_blank"
      >
        <Card />
      </a>
    </section>
    <section>
      <ThreedSlide />
    </section>
    <section>
      <a
        href="https://t.me/stvegas89"
        class="text-decoration-none white--text"
        target="_blank"
      >
        <AllGames />
      </a>
    </section>
    <!-- <section>
      <FAQ />
    </section> -->
    <section>
      <Footer />
    </section>
  </div>
</template>

<script>
import Navbar from "../components/Navbar/Navbar.vue";
import Slider from "../components/Homepage/Slider.vue";
import Card from "../components/Homepage/Card.vue";
import ThreedSlide from "../components/Homepage/3dSlide.vue";
import AllGames from "../components/Homepage/AllGames.vue";
import FAQ from "../components/Homepage/FAQ.vue";
import Footer from "../components/Homepage/Footer.vue";
export default {
  components: { Navbar, Slider, Card, ThreedSlide, AllGames, FAQ, Footer, },
};
</script>

<style>
</style>