import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import '../src/assets/style/index.css'
import Carousel3d from 'vue-carousel-3d';
import VueResource from 'vue-resource'
import Toasted from 'vue-toasted'

Vue.use(Carousel3d);
Vue.use(VueResource);

Vue.config.productionTip = false
Vue.use(Toasted,{
  duration:2200,
  position:'top-right',
  theme:'outline',
  type:'success',
  iconPack : 'material',

});

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
