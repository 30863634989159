<template>
  <div id="example">
    <div class="width">
      <v-img max-height="5vw" max-width="50%" class="mx-auto" alt="STVEGAS89 Online game"
        style="animation: blinkYellowBottom 1000ms infinite"
        src="https://res.cloudinary.com/dbzsjc7dt/image/upload/v1650703169/Game%20Online%20imgs/Frame_57_hnomb2.webp"
        lazy-src="https://res.cloudinary.com/dbzsjc7dt/image/upload/v1650703169/Game%20Online%20imgs/Frame_57_hnomb2.webp">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <!-- <h1
        class="LinearDarkYellow text-center mx-auto py-3 title-text1 game-card white--text mb-10"
        style="animation: blink 1s infinite"
      >
        ការផ្តល់ជូនពិសេស
      </h1> -->
    </div>
    <carousel-3d :perspective="0" :autoplay="true" :display="5" :width="width" :height="height">
      <slide v-for="(slide, i) in slides" :index="i" :key="i">
        <!-- <figure></figure>

        <v-img   :src="slide" :lazy-src="slide"> </v-img> -->
        <router-link to="/promotion">
          <figure>
            <img alt="STVEGAS89 Online game" :src="slide.feature_image" :lazy-src="slide" />
          </figure>
        </router-link>
      </slide>
    </carousel-3d>
    <a href="https://t.me/stvegas89" class="text-decoration-none white--text" target="_blank">
      <v-img class="" alt="STVEGAS89 Online game"
        src="https://res.cloudinary.com/dbzsjc7dt/image/upload/v1650672015/Game%20Online%20imgs/Video_Converter_Ultimate_20220422140427_ywfhfq.gif"
        lazy-src="https://res.cloudinary.com/dbzsjc7dt/image/upload/v1650672015/Game%20Online%20imgs/Video_Converter_Ultimate_20220422140427_ywfhfq.gif"><template
          v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-row> </template></v-img>
    </a>
  </div>
</template>

<script>
import { Carousel3d, Slide } from "vue-carousel-3d";
// read api
import axios from 'axios';

export default {
  // data: () => ({
  //   // slides: [
  //   //   "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1650672007/Game%20Online%20imgs/stvegas-poster/slotandfish-promo_c4vxru.webp",
  //   //   "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1650671998/Game%20Online%20imgs/stvegas-poster/Frame_48_jsgrow.webp",
  //   //   "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1650672003/Game%20Online%20imgs/stvegas-poster/livecasino-promo_u3zrhm.webp",
  //   //   "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1650672000/Game%20Online%20imgs/stvegas-poster/soccer-promo_k0vmya.webp",
  //   //   "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1650671998/Game%20Online%20imgs/stvegas-poster/daily10percent-promo_dsy4ap.webp",
  //   //   "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1650671998/Game%20Online%20imgs/stvegas-poster/5percentreturn-promo_ba3l4e.webp",
  //   //   "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1650672005/Game%20Online%20imgs/stvegas-poster/nopromo-promo_gdf3ay.webp",
  //   //   "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1650671998/Game%20Online%20imgs/stvegas-poster/20percent-promo_al6fua.webp"
  //   // ],
  //   // slides:[],

  //   // width: "380",
  //   // height: "380",
  // }),
  data() {
    return {
      // multi item
      slides: [],
      width: "380",
      height: "380",
    }
  },
  mounted() {
    this.getSlides();
  },
  methods: {
    getSlides() {
      axios.get('https://backend.stvegas89.com/api/promotion2/slides').then(res => {
        this.slides = res.data.data
        console.log(this.data);
        // console.log(res);
      });
    },
  },

  created() {
    if (window.innerWidth <= 600) {
      this.width = "200";
      this.height = "200";
    }
    if (window.innerWidth >= 3500) {
      this.width = "1000";
      this.height = "1000";
    }
  },
  components: {
    Carousel3d,
    Slide,
  },
};
</script>

<style scoped></style>