<template>
  <div class="width my-10" style="background-color: rgba(0, 0, 0, 0.25)">
    <div class="mx-auto text-center white--text py-10">
      <h1 class="my-0 py-0 title-text1">STVEGAS89</h1>
      <p class="title-text">ONLINE CASINO</p>
      <div>
        <p class="title-text">ទំនាក់ទំនង</p>
        <div class="d-flex justify-center my-5">
          <div class="d-flex" style="float: right">
            <div v-for="(media, i) in medias" :key="i">
              <a :href="media.link" target="_blank">
                <v-img  
                  max-height="8vw"
                  max-width="8vw"
                  class="mx-2"
                  alt="STVEGAS89 Online game"
                  :src="media.icon"
                  :lazy-src="media.icon"
                  ><template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row> </template
                ></v-img>
              </a>
            </div>
          </div>
        </div>
        <a href="https://t.me/stvegas89" target="_blank">
          <v-img  
            class="mx-auto qrcode"
            alt="STVEGAS89 Online game"
            src="https://res.cloudinary.com/dbzsjc7dt/image/upload/v1649309996/Game%20Online%20imgs/stvegas_telegram_2_ovwdfs.webp"
            lazy-src="https://res.cloudinary.com/dbzsjc7dt/image/upload/v1649309996/Game%20Online%20imgs/stvegas_telegram_2_ovwdfs.webp"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    medias: [
      {
        icon: "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1648909855/Game%20Online%20imgs/Facebook_wvekjx.webp",
        link: "http://m.me/109649748119492/?messaging_source=source%3Apages%3Amessage_shortlink&source_id=1441792",
      },
      {
        icon: "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1648909855/Game%20Online%20imgs/Telegram_yj9ykg.png",
        link: "https://t.me/stvegas89",
      },
      {
        icon: "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1649228905/Game%20Online%20imgs/image_7_g4dc4r.webp",
        link: "https://line.me/R/ti/p/@656qogkh",
      },
      {
        icon: "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1649217574/Game%20Online%20imgs/image_8_ahpt8w.webp",
        link: "tel:+85586896003",
      },
    ],
  }),
};
</script>

<style scoped>
@media screen and (max-width: 600px) {
  .qrcode {
    max-height: 30vw;
    max-width: 30vw;
  }
}
@media screen and (min-width: 600px) {
  .qrcode {
    max-height: 14vw;
    max-width: 14vw;
  }
}
</style>