<template>
  <div>
    <!-- <v-app class="mx-0 px-0"> -->
    <div class="mx-0 px-0">
      <a
        href="https://t.me/stvegas89"
        class="text-decoration-none white--text"
        target="_blank"
      >
        <v-img
          class="mx-0 px-0 navImg"
          alt="STVEGAS89 Online game"
          src="https://res.cloudinary.com/dbzsjc7dt/image/upload/v1649310005/Game%20Online%20imgs/Frame_25_cdctr3.webp"
          lazy-src="https://res.cloudinary.com/dbzsjc7dt/image/upload/v1649310005/Game%20Online%20imgs/Frame_25_cdctr3.webp"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </a>
      <div class="LinearRed">
        <!-- <div class="width d-flex justify-center red ">
          <h1 v-for="(navbar, index) in navbars" :key="index" class="md-text1 py-3 white--text mx-1 text-center">
            <v-icon left class="my-auto white--text" :style="navbar.style">{{
              navbar.icon
            }}</v-icon>
            {{ navbar.text }}
          </h1>
          <a
            href="https://t.me/+OtZtGbCuEps0NTZl"
            class="text-decoration-none white--text my-auto"
            target="_blank"
          >
            <button
              class="rounded-pill white px-4 py-1 my-auto sm-text mr-2 btn"
              style="float: right"
            >
              ក្រុម១៨+
            </button>
          </a>
        </div> -->
        <v-tabs class="width white--text"
          height="100%" dark background-color="transparent">
          <v-tab
            class=" md-text1 mx-auto tab_nav"
            style="padding-left: 0px; padding-right: 0px;"
            v-for="(navbar, index) in navbars"
            :key="index"
            :to="navbar.link"
            :style="navbar.style"
          >
            <v-icon
              style="padding-right: 2px"
              class="my-auto"
              :style="navbar.style"
              >{{ navbar.icon }}</v-icon
            >
            {{ navbar.text }}
          </v-tab>
          <a
            href="https://t.me/+OtZtGbCuEps0NTZl"
            class="text-decoration-none white--text my-auto"
            target="_blank"
          >
            <button
              class="rounded-pill white px-4 py-1 my-auto sm-text mr-2 btn"
              style="float: right;"
            >
              ក្រុម១៨+
            </button>
          </a>
        </v-tabs>
      </div>
    </div>
    <!-- </v-app> -->
  </div>
</template>

<script>
export default {
  data: () => ({
    navbars: [
      {
        text: "ទំព័រដើម",
        icon: "fas fa-house",
        link: "/",
      },
      {
        text: "ប្រូម៉ូសិន",
        icon: "fas fa-futbol",
        link: "/promotion",
        style: "animation: blink 1s infinite",
      },
      {
        text: "លក្ខន្តិកៈ",
        icon: "fas fa-hand-holding-dollar",
        link: "/condition",
      },
      {
        text: "ទំនាក់ទំនង",
        icon: "fas fa-comment",
        link: "/contact",
      },
      {
        text: "ទំនាក់ទំនង",
        icon: "fas fa-comment",
        link: "/contact",
      },
      {
        text: "ចុះឈ្មោះ",
        icon: "fas fa-sign-in-alt ",
        link: "/register",
      },
    ],
  }),
};
</script>

<style scoped>
.v-tabs {
  height: 100%;
}
.v-tab {
  padding: 12px;
  min-width: 1px !important;
}
@media screen and (max-width: 600px) {
  .navImg {
    height: 70px;
  }
}
@media screen and (min-width: 600px) {
  .tab_nav{
    width: 100%;
  }
}
</style>