<template>
  <v-app>
    <div
      style="
        height: 100%;
        width: 100vw;
        background-attachment: fixed;
        background: rgba(0,0,0,0.2);
        opacity: 0.2;
        position: absolute;
        z-index: 0;
      "
    >
      <!-- <p class=" blue" style="font-size: 100px; margin-top: 600px ;position: fixed">dhdhd</p> -->
      <div class="container1">
        <div class="loader">
          <span class="coin"></span>
          <span class="money"></span>
          <span class="coin"></span>
          <span class="money"></span>
          <span class="coin"></span>
          <span class="money"></span>
          <span class="coin"></span>
          <span class="money"></span>
          <span class="coin"></span>
          <span class="money"></span>
          <span class="coin"></span>
          <span class="money"></span>
          <span class="coin"></span>
          <span class="money"></span>
          <span class="coin"></span>
          <span class="money"></span>
          <span class="coin"></span>
          <span class="money"></span>
          <span class="coin"></span>
          <span class="money"></span>
          <span class="coin"></span>
          <span class="money"></span>
          <span class="coin"></span>
          <span class="money"></span>
          <span class="coin"></span>
          <span class="money"></span>
          <span class="coin"></span>
          <span class="money"></span>
           <span class="coin"></span>
          <span class="money"></span>
          <span class="coin"></span>
          <span class="money"></span>
          <span class="coin"></span>
          <span class="money"></span>
        </div>
      </div>
    </div>
    <nav
      style="position: -webkit-sticky; position: sticky; top: 0; z-index: 10"
    >
      <Navbar />
    </nav>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar/Navbar.vue";

export default {
  name: "App",
  data: () => ({
    //
  }),
  components: { Navbar },
};
</script>
<style>
.v-toolbar__content {
  margin: 0 !important;
  padding: 0 !important;
  display: block !important;
}
* {
  font-family: "Kantumruy", sans-serif;
}

/* main container */
.container1 {
  top: 0px;
  /* background:#fff; */
  width: 100%;
  height: 100vh;
  min-height: 800px;
  position: fixed;
  left: 0%;
  /* 	background:#1E1E20 url(http://premiumcoding.com/CSSTricks/fallingLeaves/autumn.jpg) center top no-repeat; */
  background-size: cover;
}

.loader {
  text-align: center;
}

.loader span:nth-child(5n + 5) {
  -webkit-animation-delay: 1.3s;
  -moz-animation-delay: 1.3s;
}
.loader span:nth-child(3n + 2) {
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
}
.loader span:nth-child(2n + 5) {
  -webkit-animation-delay: 1.7s;
  -moz-animation-delay: 1.7s;
}

.loader span:nth-child(3n + 10) {
  -webkit-animation-delay: 2.7s;
  -moz-animation-delay: 2.7s;
}
.loader span:nth-child(7n + 2) {
  -webkit-animation-delay: 3.5s;
  -moz-animation-delay: 3.5s;
}
.loader span:nth-child(4n + 5) {
  -webkit-animation-delay: 5.5s;
  -moz-animation-delay: 5.5s;
}
.loader span:nth-child(3n + 7) {
  -webkit-animation-delay: 8s;
  -moz-animation-delay: 8s;
}

@media screen and (min-width: 1000px) {
  .loader .coin {
    display: inline-block;

    margin: -280px 40px 54px -34px;
    background: url("https://res.cloudinary.com/dbzsjc7dt/image/upload/v1650371359/Game%20Online%20imgs/Empty-Gold-Coin-Transparent_nz7ry3.webp");
    background-size: contain;
    background-repeat: no-repeat;

    -webkit-animation: loader 10s infinite linear;
    -moz-animation: loader 10s infinite linear;
  }
  .loader .money {
    display: inline-block;

    margin: -280px 40px 54px -34px;
    background: url("https://res.cloudinary.com/dbzsjc7dt/image/upload/v1650371404/Game%20Online%20imgs/Screen_Shot_2013-10-08_at_9.13.38_AM_n9gbgm.webp");
    background-size: contain;
    /* background-repeat: no-repeat; */

    -webkit-animation: loader 10s infinite linear;
    -moz-animation: loader 10s infinite linear;
  }
}
@media screen and (max-width: 1000px) {
  .loader .money {
    display: inline-block;

    margin: -280px 40px 54px -34px;
    background: url("https://res.cloudinary.com/dbzsjc7dt/image/upload/v1650371404/Game%20Online%20imgs/Screen_Shot_2013-10-08_at_9.13.38_AM_n9gbgm.webp");
    background-size: contain;
    background-repeat: no-repeat;

    -webkit-animation: loader 10s infinite linear;
    -moz-animation: loader 10s infinite linear;
  }
}
@-webkit-keyframes loader {
  0% {
    width: 80px;
    height: 80px;
    opacity: 1;

    -webkit-transform: translate(0, 0px) rotateZ(0deg);
  }
  75% {
    width: 80px;
    height: 80px;
    opacity: 1;

    -webkit-transform: translate(100px, 600px) rotateZ(270deg);
  }
  100% {
    width: 80px;
    height: 80px;
    opacity: 0;

    -webkit-transform: translate(150px, 800px) rotateZ(360deg);
  }
}
@-moz-keyframes loader {
  0% {
    width: 80px;
    height: 80px;
    opacity: 1;

    -webkit-transform: translate(0, 0px) rotateZ(0deg);
  }
  75% {
    width: 80px;
    height: 80px;
    opacity: 1;

    -webkit-transform: translate(100px, 600px) rotateZ(270deg);
  }
  100% {
    width: 80px;
    height: 80px;
    opacity: 0;

    -webkit-transform: translate(150px, 800px) rotateZ(360deg);
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
@keyframes blink {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

@-webkit-keyframes vertical {
  0% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes vertical {
  0% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>
