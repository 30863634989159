<template>
  <div class="width white--text pb-10">
    <div class="pb-5">
      <h1 class="title-text">សំណួរពេញនិយម</h1>
      <p class="md-text pt-2">ពួកយើងត្រៀមខ្លួនរួ​ចជាស្រេចដើម្បីឆ្លើយសំណួរ</p>
    </div>
    <v-row justify="center" class="mx-0 px-0">
      <v-expansion-panels>
        <v-expansion-panel
          v-for="(item, i) in 5"
          :key="i"
          class="primary white--text"
          style="margin: 1px 0"
        >
          <v-expansion-panel-header expand-icon="mdi-menu-down" class="md-text py-5"
            >តើធ្វើដូចម្តេចទើបអាចលេងបាន?</v-expansion-panel-header
          >
          <v-expansion-panel-content class="md-text">
            ងាយៗ លោកអ្នកគ្រាន់តែជ្រើសរើសវិធីទំនាក់ទំនងពី (/លីង/)
            រួចប្រាប់ពីហ្គេមដែលចង់លេង។
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
i.v-icon.notranslate.mdi.mdi-chevron-down.theme--light {
  color: white !important;
}
</style>