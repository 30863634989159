<template>
  <div class="fullWidth">
    <v-container class="pa-0" style="width: 100%">
      <v-row class="ma-3">
        <template>
          <v-col
            v-for="(game, n) in games"
            :key="n"
            class="d-flex child-flex pa-1 mx-auto"
            cols="4"
          >
            <a :href="game.link" target="_blank" class="text-decoration-none">
              <v-hover v-slot="{ hover }">
                <v-card
                  class="rounded-0 card ma-md-3 ma-1"
                  style="background-color: transparent"
                >
                  <div style="position: relative">
                    <div>
                      <v-img
                        alt="STVEGAS89 Online game"
                        :src="game.img"
                        :lazy-src="game.img"
                        max-width="100%"
                        max-height="100%"
                        style="margin: 1px"
                        class="text-center"
                        :class="{ 'on-hover': hover }"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </div>
                    <div v-show="hover" class="center">
                      <v-img
                        alt="STVEGAS89 Online game"
                        src="https://res.cloudinary.com/dbzsjc7dt/image/upload/v1649149229/Game%20Online%20imgs/play_pnabbc.svg"
                        lazy-src="https://res.cloudinary.com/dbzsjc7dt/image/upload/v1649149229/Game%20Online%20imgs/play_pnabbc.svg"
                        ><template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row> </template
                      ></v-img>
                      <p class="white--text px-auto text-center my-0 xs-text">
                        លេង
                      </p>
                    </div>
                  </div>
                  <div
                    class="mb-2"
                    :class="hover ? 'LinearDarkYellow' : 'LinearDarkRed'"
                    :style="hover ? 'animation: blinkYellow 10000ms infinite' : 'animation: blinkRed 1000ms infinite'"
                  >
                    <h1 class="white--text text-center md-text py-1">
                      {{ game.title }}
                    </h1>
                  </div>
                </v-card>
              </v-hover>
            </a>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    games: [
      {
        id: 1,
        img: "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1648909861/Game%20Online%20imgs/Rectangle_479_jlotl5.webp",
        title: "ស្លតហ្គេម Joker123",
        //link: "https://www.joker123.net/",
        link: "https://t.me/stvegas89",
      },
      {
        id: 2,
        img: "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1648909859/Game%20Online%20imgs/Rectangle_479_1_vrvgyl.webp",
        title: "ស្លតហ្គេម Live22",
        //link: "https://www.live22.com",
        link: "https://t.me/stvegas89",
      },
      {
        id: 3,
        img: "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1648909859/Game%20Online%20imgs/Rectangle_479_2_rwuy5r.webp",
        title: "ស្លតហ្គេម Mega888",
        //link: "http://m.mega688.com",
        link: "https://t.me/stvegas89",
      },
      {
        id: 4,
        img: "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1648909863/Game%20Online%20imgs/Rectangle_479_3_hzoelc.webp",
        title: "ស្លតហ្គេម Pussy888",
        //link: "http://dl1.pussy888.com",
        link: "https://t.me/stvegas89",
      },
      {
        id: 5,
        img: "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1648909860/Game%20Online%20imgs/Rectangle_479_4_yttx3a.webp",
        title: "ស្លតហ្គេម 918kiss",
        //link: "http://tm.918kiss.com",
        link: "https://t.me/stvegas89",
      },
      {
        id: 6,
        img: "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1648909857/Game%20Online%20imgs/Rectangle_479_5_z4hljw.webp",
        title: "កាស៊ីណូ BETWOS",
        //link: "https://www.wosbet2.com",
        link: "https://t.me/stvegas89",
      },
      {
        id: 7,
        img: "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1648909857/Game%20Online%20imgs/Rectangle_479_6_amye0a.webp",
        title: "Live កាស៊ីណូ GD88",
        //link: "https://www.gd88.org",
        link: "https://t.me/stvegas89",
      },
      {
        id: 8,
        img: "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1648909856/Game%20Online%20imgs/Rectangle_479_7_pean7z.webp",
        title: "ចាក់បាល់ ibet789",
        //link: "https://five789.com",
        link: "https://t.me/stvegas89",
      },
      {
        id: 9,
        img: "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1648909856/Game%20Online%20imgs/Rectangle_479_8_ggpuz8.webp",
        title: "ចាក់បាល់ 855bet",
        //link: "https://www.855bet.com",
        link: "https://t.me/stvegas89",
      },
      {
        id: 10,
        img: "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1648911464/Game%20Online%20imgs/Rectangle_479_9_xvh1ts.webp",
        title: "ចាក់បាល់ Sbobet",
        //link: "https://www.sbobet.com/euro",
        link: "https://t.me/stvegas89",
      },
      {
        id: 11,
        img: "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1648911474/Game%20Online%20imgs/Rectangle_479_10_jjfzzp.webp",
        title: "ជល់មាន់ Wa855",
        //link: "https://wa855.net/login",
        link: "https://t.me/stvegas89",
      },
      {
        id: 12,
        img: "https://res.cloudinary.com/dbzsjc7dt/image/upload/v1648911479/Game%20Online%20imgs/Rectangle_479_11_h9wa3l.webp",
        title: "ជល់មាន់ F88",
        //link: "https://f88.live",
        link: "https://t.me/stvegas89",
      },

      {
        id: 13,
        img: "https://res.cloudinary.com/dhplavux6/image/upload/v1689694969/stvegas89/zlotxo.net_xlebxb.png",
        title: "SLOT XO",
        //link: "https://www.slotxo.com/",
        link: "https://t.me/stvegas89",
      },
      {
        id: 14,
        img: "https://res.cloudinary.com/dhplavux6/image/upload/v1689694968/stvegas89/wmcasino_ovhyg5.png",
        title: "WM CASINO",
        //link: "https://wmcasino.com/",
        link: "https://t.me/stvegas89",
      },
      {
        id: 15,
        img: "https://res.cloudinary.com/dhplavux6/image/upload/v1689694968/stvegas89/sexygaming_evtrgo.png",
        title: "SEXY GAMING",
        //link: "https://sexygaming.bet/",
        link: "https://t.me/stvegas89",
      },
      
      {
        id: 16,
        img: "https://res.cloudinary.com/dhplavux6/image/upload/v1689694968/stvegas89/pgslot.com_nhhm3p.png",
        title: "PG SLOT",
        //link: "https://pgslot.co/home",
        link: "https://t.me/stvegas89",
      },
      {
        id: 17,
        img: "https://res.cloudinary.com/dhplavux6/image/upload/v1689694967/stvegas89/m99_bxi1wv.png",
        title: "មាន់ជល់ M99",
        //link: "https://m99.asia/login",
        link: "https://t.me/stvegas89",
      },
     
      {
        id: 18,
        img: "https://res.cloudinary.com/dhplavux6/image/upload/v1689694967/stvegas89/live789.com_aufzok.png",
        title: "Live789",
        //link: "https://live789.com/Default3.aspx?r=1784413560",
        link: "https://t.me/stvegas89",
      },
      {
        id: 19,
        img: "https://res.cloudinary.com/dhplavux6/image/upload/v1689694967/stvegas89/loto99.com_yelvbp.jpg",
        title: "ឆ្នោតឡូតូ 99",
        //link: "https://lotto99.me/login",
        link: "https://t.me/stvegas89",
      },
      {
        id: 20,
        img: "https://res.cloudinary.com/dhplavux6/image/upload/v1689694966/stvegas89/cf789_l7w7ln.png",
        title: "មាន់ជល់ CF789",
        //link: "https://cf789.live/login",
        link: "https://t.me/stvegas89",
      },
      
      {
        id: 21,
        img: "https://res.cloudinary.com/dhplavux6/image/upload/v1689694966/stvegas89/2betway.co_blksfp.jpg",
        title: "2BETWAY",
        //link: "https://2betway.co/",
        link: "https://t.me/stvegas89",
      },
      {
        id: 22,
        img: "https://res.cloudinary.com/dhplavux6/image/upload/v1689694966/stvegas89/cmdbet_mfcpbo.jpg",
        title: "CMD368",
        //link: "https://www.cmdbet.com/",
        link: "https://t.me/stvegas89",
      },
      {
        id: 23,
        img: "https://res.cloudinary.com/dhplavux6/image/upload/v1689703941/stvegas89/2bwinner_fa4szt.png",
        title: "2BWINNER",
        //link: "https://2bwinner.net/login",
        link: "https://t.me/stvegas89",
      },
      {
        id: 24,
        img: "https://res.cloudinary.com/dhplavux6/image/upload/v1689703941/stvegas89/mohasambath_m4ka56.png",
        title: "ឆ្នោតមហាសម្បត្តិ  ",
        //link: "https://www.mohasambath.com/login",
        link: "https://t.me/stvegas89",
      },
      {
        id: 25,
        img: "https://res.cloudinary.com/dhplavux6/image/upload/v1689703941/stvegas89/ibcbet_hkrwjd.png",
        title: "IBCBET",
        //link: "https://ibcbet77.com/",
        link: "https://t.me/stvegas89",
      },
      {
        id: 26,
        img: "https://res.cloudinary.com/dhplavux6/image/upload/v1689703941/stvegas89/aa2288_fitba0.png",
        title: "មាន់ជល់ AA2288",
        //link: "https://aa2288.net/login",
        link: "https://t.me/stvegas89",
      },
      {
        id: 27,
        img: "https://res.cloudinary.com/dhplavux6/image/upload/v1689703941/stvegas89/agasiagaming_i6jgxk.png",
        title: "AG ASIA GAMING",
        //link: "https://www.softgamings.com",
        link: "https://t.me/stvegas89",
      },
      {
        id: 28,
        img: "https://res.cloudinary.com/dhplavux6/image/upload/v1689703942/stvegas89/2dbet_l8vlby.png",
        title: "2DBET",
        //link: "http://www.2DBET.com",
        link: "https://t.me/stvegas89",
      },
      {
        id: 29,
        img: "https://res.cloudinary.com/dhplavux6/image/upload/v1689703942/stvegas89/sb24_v7rle7.png",
        title: "SABONG24",
        //link: "https://sb24.tv/login",
        link: "https://t.me/stvegas89",
      },
      {
        id: 30,
        img: "https://res.cloudinary.com/dhplavux6/image/upload/v1689703942/stvegas89/wbet_hxwdc9.png",
        title: "WBET",
        //link: "https://mywbet.com/",
        link: "https://t.me/stvegas89",
      },
    ],
  }),
};
</script>

<style scoped>
.gameTitle {
  animation: blinkRed 1000ms infinite;
}

.on-hover {
  opacity: 0.5;
}
/* #gameTitle {
  background: blue;
}
.on-hover > #gameTitle {
  background: #000;
} */
/* .v-card {
  transition: opacity 0.4s ease-in-out;
  border: 2px solid rgba(0, 0, 0, 1);
  box-shadow: 0 5px 5px #000;
} */

/* *{
  box-shadow: 0px 0px 4px 3px  rgba(255, 167, 3, 0.7), rgba(218, 136, 13, 0.582);
} */
</style>